// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('profiles/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/profile/profileList`, params)
  return {
    params,
    data: response.data.Response.items,
    totalPages: response.data.Response.total_items
  }
})

export const getProfile = createAsyncThunk('profiles/getProfile', async id => {
  let response = {}
  if (id > 0) {
    response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/profile/profileEdit/${id}`)
    return response.data.Response.item
  } else return response
})

export const addProfile = createAsyncThunk('profiles/addProfile', async (CreateProfile, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/profile/profileCreate`, {
    Request: {
      CreateProfile
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().profiles.params))
  await dispatch(getAllData())
  return profile
})

export const updateProfile = createAsyncThunk('profiles/addUpdate', async (UpdateProfile, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_API}Schedule/profile/profileUpdate`, {
    Request: {
      UpdateProfile
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().profiles.params))
  return profile
})

export const deleteProfile = createAsyncThunk('profiles/deleteProfile', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_BASE_API}Schedule/profile/profileDelete/${id}`)

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().profiles.params))
  return id
})

export const appProfilesSlice = createSlice({
  name: 'profiles',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedProfile: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.selectedProfile = action.payload
      })
  }
})

export default appProfilesSlice.reducer
