// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const fetchServices = createAsyncThunk('appServices/fetchServices', async calendarId => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/services/servicesList`, {params: { calendarId }})
   return response.data.Response.items
})

export const addLogistic = createAsyncThunk('appServices/addLogistic', async (CreateLogistic, {dispatch}) => {

   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/logistic/logisticCreate`, {
         Request: {
            CreateLogistic
         }
      }
   )

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(fetchServices(CreateLogistic.calendarId))

   return CreateLogistic
})

export const updateLogistic = createAsyncThunk('appServices/updateLogistic', async (UpdateLogistic, {dispatch}) => {

   const response = await axios.put(`${process.env.REACT_APP_BASE_API}Schedule/logistic/logisticUpdate`, {
         Request: {
            UpdateLogistic
         }
      }
   )

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(fetchServices(UpdateLogistic.calendarId))

   return UpdateLogistic
})

export const addTypeOfExamCalendar = createAsyncThunk('appServices/addTypeOfExam', async (CreateTypeOfExamCalendar, {dispatch}) => {

   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/typeOfExamCalendar/typeOfExamCalendarCreate`, {
         Request: {
            CreateTypeOfExamCalendar
         }
      }
   )

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(fetchServices(CreateTypeOfExamCalendar.calendarId))

   return CreateTypeOfExamCalendar
})

export const updateTypeOfExamCalendar = createAsyncThunk('appServices/updateTypeOfExamCalendar', async (UpdateTypeOfExamCalendar, {dispatch}) => {

   const response = await axios.put(`${process.env.REACT_APP_BASE_API}Schedule/typeOfExamCalendar/typeOfExamCalendarUpdate`, {
         Request: {
            UpdateTypeOfExamCalendar
         }
      }
   )

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(fetchServices(UpdateTypeOfExamCalendar.calendarId))

   return UpdateTypeOfExamCalendar
})

export const deleteTypeOfExamCalendar = createAsyncThunk('appServices/deleteTypeOfExamCalendar', async (DeleteTypeOfExamCalendar, {dispatch}) => {

   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/typeOfExamCalendar/typeOfExamCalendarDelete`, {
         Request: {
            DeleteTypeOfExamCalendar
         }
      }
   )

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(fetchServices(DeleteTypeOfExamCalendar.calendarId))

   return DeleteTypeOfExamCalendar
})

export const appServicesSlice = createSlice({
   name: 'appServices',
   initialState: {
      logistic: [],
      typeOfExam: []
   },
   reducers: {
      selectServices: (state, action) => {
         state.logistic = action.payload.logistic
         state.typeOfExam = action.payload.typeOfExam
      }
   },
   extraReducers: builder => {
      builder
         .addCase(fetchServices.fulfilled, (state, action) => {
            state.logistic = action.payload.logistic
            state.typeOfExam = action.payload.typeOfExam
         })
   }
})

export const {selectServices} = appServicesSlice.actions

export default appServicesSlice.reducer