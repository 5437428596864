// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('users/getData', async params => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/reportCall/reportCallList`, {params})
   return {
      params,
      data: response.data.Response.items,
      totalPages: response.data.Response.total_items
   }
})

export const getReport = createAsyncThunk('appCalendar/getReport', params => {
   const response = axios.get(`${process.env.REACT_APP_BASE_API}Schedule/reportCall/reportCallExport`, {params})
   return response.data.Response.items
})

export const appReportScheduleSlice = createSlice({
   name: 'reportCall',
   initialState: {
      data: [],
      total: 0,
      params: {},
      report: []
   },
   reducers: {},
   extraReducers: builder => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
         })
         .addCase(getReport.fulfilled, (state, action) => {
            state.report = action.payload
         })
   }
})

export default appReportScheduleSlice.reducer