// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('typeActivity/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/typeActivity/typeActivityList`, {params})
  return {
    params,
    data: response.data.Response.items,
    totalPages: response.data.Response.total_items
  }
})

export const getTypeActivity = createAsyncThunk('typeActivity/getTypeActivity', async id => {
  let response = {}
  if (id > 0) {
    response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/typeActivity/typeActivityEdit/${id}`)
    return response.data.Response.item
  } else return response
})

export const addTypeActivity = createAsyncThunk('typeActivity/addTypeActivity', async (CreateTypeActivity, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/typeActivity/typeActivityCreate`, {
    Request: {
      CreateTypeActivity
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().typeActivity.params))
  await dispatch(getAllData())
  return CreateTypeActivity
})

export const updateTypeActivity = createAsyncThunk('typeActivity/addUpdate', async (UpdateTypeActivity, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_API}Schedule/typeActivity/typeActivityUpdate`, {
    Request: {
      UpdateTypeActivity
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().typeActivity.params))
  return UpdateTypeActivity
})

export const deleteTypeActivity = createAsyncThunk('typeActivity/deleteTypeActivity', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_BASE_API}Schedule/typeActivity/typeActivityDelete/${id}`)

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().typeActivity.params))
  return id
})

export const appTypeActivitySlice = createSlice({
  name: 'typeActivity',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTypeActivity: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTypeActivity.fulfilled, (state, action) => {
        state.selectedTypeActivity = action.payload
      })
  }
})

export default appTypeActivitySlice.reducer
