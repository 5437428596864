// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from "react-hot-toast"

export const getData = createAsyncThunk('users/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/user/userList`, {params})
  return {
    params,
    data: response.data.Response.items,
    totalPages: response.data.Response.total_items
  }
})

export const getUser = createAsyncThunk('users/getUser', async id => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/user/userEdit/${id}`)
  return response.data.Response.item
})

export const addUser = createAsyncThunk('users/addUser', async (CreateUser, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/user/userCreate`, {
    Request: {
      CreateUser
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().users.params))
  return CreateUser
})

export const updateUser = createAsyncThunk('users/updateUser', async (UpdateUser, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/user/userUpdate`, {
    Request: {
      UpdateUser
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().users.params))
  await dispatch(getUser(getState().users.selectedUser.id))
  return UpdateUser
})

export const configUser = createAsyncThunk('users/configUser', async (ConfigUser, { dispatch, getState }) => {
  const response = await axios.patch(`${process.env.REACT_APP_BASE_API}Schedule/user/configUser`, {
    Request: {
      ConfigUser
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().users.params))
  return ConfigUser
})

export const changePassword = createAsyncThunk('users/changePassword', async (ChangePassword, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/auth/changePassword`, {
    Request: {
      ChangePassword
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().users.params))
  return ChangePassword
})

export const changeStatus = createAsyncThunk('users/changeStatus', async (ChangeStatusUser, { dispatch, getState }) => {
  const response = await axios.patch(`${process.env.REACT_APP_BASE_API}Schedule/user/userChangeStatus`, {
    Request: {
      ChangeStatusUser
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().users.params))
  return ChangeStatus
})

export const changeBlock = createAsyncThunk('users/changeBlock', async (ChangeBlockUser, { dispatch, getState }) => {
  const response = await axios.patch(`${process.env.REACT_APP_BASE_API}Schedule/user/userChangeBlock`, {
    Request: {
      ChangeBlockUser
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().users.params))
  return ChangeBlock
})

export const appUsersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appUsersSlice.reducer
