// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from './authentication'
import calendar from '@src/views/calendar/store'
import users from '@src/views/users/store'
import profiles from '@src/views/profile/store'
import services from '@src/views/addServiceSteps/store'
import reportSchedule from '@src/views/reportSchedule/store'
import menus from '@src/views/menu/store'
import typeOfExams from '@src/views/typeOfExam/store'
import typeActivity from '@src/views/typeActivity/store'
import activity from '@src/views/activity/store'
import protocol from '@src/views/protocol/store'
import call from '@src/views/call/store'
import campaign from '@src/views/campaign/store'
import contact from '@src/views/contact/store'
import reportCall from '@src/views/reportCall/store'
import reportFinance from '@src/views/reportFinance/store'

const rootReducer = {
    navbar, 
    layout,
    auth,
    calendar,
    users,
    profiles,
    services,
    reportSchedule,
    menus,
    typeOfExams,
    typeActivity,
    activity,
    protocol,
    call,
    campaign,
    contact,
    reportCall,
    reportFinance
}

export default rootReducer
