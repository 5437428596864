// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('campaign/getData', async params => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/campaign/campaignList`, {params})
   return {
      params,
      data: response.data.Response.items,
      totalPages: response.data.Response.total_items
   }
})

export const getCampaign = createAsyncThunk('campaign/getCampaign', async id => {
   let response = {}
   if (id > 0) {
      response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/campaign/campaignEdit/${id}`)
      return response.data.Response.item
   } else return response
})

export const addCampaign = createAsyncThunk('campaign/addCampaign', async (CreateCampaign, {dispatch, getState}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/campaign/campaignCreate`, {
      Request: {
         CreateCampaign
      }
   })

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getData(getState().campaign.params))
   await dispatch(getAllData())
   return CreateCampaign
})

export const updateCampaign = createAsyncThunk('campaign/addUpdate', async (UpdateCampaign, {dispatch, getState}) => {
   const response = await axios.put(`${process.env.REACT_APP_BASE_API}Schedule/campaign/campaignUpdate`, {
      Request: {
         UpdateCampaign
      }
   })

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getData(getState().campaign.params))
   return UpdateCampaign
})

export const listContactForCampaign = createAsyncThunk('campaign/listContactForCampaign', async params => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/campaign/listContactForCampaign`, {params})
   return {
      dataListContact: response.data.Response.items
   }
})

export const deleteCampaign = createAsyncThunk('campaign/deleteCampaign', async (id, {dispatch, getState}) => {
   const response = await axios.delete(`${process.env.REACT_APP_BASE_API}Schedule/campaign/campaignDelete/${id}`)

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getData(getState().campaign.params))
   return id
})

/** Request for Setup */
export const getDataCampaignSetup = createAsyncThunk('campaign/getDataCampaignSetup', async id => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/campaignSetup/campaignSetupEdit/${id}`)
   return {
      dataSetup: response.data.Response.item
   }
})

export const addCampaignSetup = createAsyncThunk('campaign/addCampaignSetup', async (CreateCampaignSetup, {
   dispatch,
   getState
}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/campaignSetup/campaignSetupCreate`, {
      Request: {
         CreateCampaignSetup
      }
   })

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   const campaignId = getState().campaign.selectedCampaign.id
   await dispatch(getDataCampaignSetup(campaignId))

   return CreateCampaignSetup
})

// ** Upload contact
export const uploadContact = createAsyncThunk('campaign/uploadContact', async (UploadFile, {
   dispatch,
   getState
}) => {

   const uniqueId = Math.random().toString(16).slice(2)

   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/campaign/uploadContact`, UploadFile, {
      headers: {
         'Content-Type': `multipart/form-data; boundary=${uniqueId}`
      }
   })

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(listContactForCampaign({campaignId: getState().campaign.selectedCampaign.id}))

   return UploadContact
})

export const findContact = createAsyncThunk('campaign/findContact', async FindContact => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/campaign/findContact`, {
      Request: {
         FindContact
      }
   })

   return {
      dataFind: response.data.Response.items
   }
})

export const addContact = createAsyncThunk('campaign/addContact', async (AddContact, {dispatch, getState}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/campaign/addContact`, {
      Request: {
         AddContact
      }
   })

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }
   await dispatch(listContactForCampaign({campaignId: getState().campaign.selectedCampaign.id}))

   return AddContact
})
export const appCampaignsSlice = createSlice({
   name: 'campaign',
   initialState: {
      data: [],
      total: 1,
      params: {},
      allData: [],
      selectedCampaign: [],
      dataSetup: [],
      dataFind: [],
      dataListContact: []
   },
   reducers: {},
   extraReducers: builder => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
         })
         .addCase(getCampaign.fulfilled, (state, action) => {
            state.selectedCampaign = action.payload
         })
         .addCase(getDataCampaignSetup.fulfilled, (state, action) => {
            state.dataSetup = action.payload.dataSetup
         })
         .addCase(findContact.fulfilled, (state, action) => {
            state.dataFind = action.payload.dataFind
         })
         .addCase(listContactForCampaign.fulfilled, (state, action) => {
            state.dataListContact = action.payload.dataListContact
         })
   }
})

export default appCampaignsSlice.reducer
