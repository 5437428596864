// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('contact/getData', async params => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactList`, {params})
   return {
      params,
      data: response.data.Response.items,
      totalPages: response.data.Response.total_items
   }
})

export const getContact = createAsyncThunk('contact/getContact', async id => {
   let response = {}
   if (id > 0) {
      response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactEdit/${id}`)
      return response.data.Response.item
   } else return response
})

export const addContact = createAsyncThunk('contact/addContact', async (CreateContact, {dispatch, getState}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactCreate`, {
      Request: {
         CreateContact
      }
   })

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getData(getState().contact.params))
   await dispatch(getAllData())
   return CreateContact
})

export const updateContact = createAsyncThunk('contact/addUpdate', async (UpdateContact, {dispatch, getState}) => {
   const response = await axios.put(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactUpdate`, {
      Request: {
         UpdateContact
      }
   })

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getData(getState().contact.params))
   return UpdateContact
})

export const deleteContact = createAsyncThunk('contact/deleteContact', async (id, {dispatch, getState}) => {
   const response = await axios.delete(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactDelete/${id}`)

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(getData(getState().contact.params))
   return id
})


/** Request for Medicine */
export const getDataContactMedicine = createAsyncThunk('contact/getDataContactMedicine', async params => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactMedicineList`, {params})
   return {
      dataMedicine: response.data.Response.items
   }
})

export const addContactMedicine = createAsyncThunk('contact/addContactMedicine', async (CreateContactMedicine, {
   dispatch,
   getState
}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactMedicineCreate`, {
      Request: {
         CreateContactMedicine
      }
   })

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   const contactId = getState().contact.selectedContact.id === undefined ? CreateContactMedicine.contactId : getState().contact.selectedContact.id
   await dispatch(getDataContactMedicine({contactId}))
   return CreateContactMedicine
})

/** Request for Diseases */
export const getDataContactDiseases = createAsyncThunk('contact/getDataContactDiseases', async params => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactDiseasesList`, {params})
   return {
      dataDiseases: response.data.Response.items
   }
})

export const addContactDiseases = createAsyncThunk('contact/addContactDiseases', async (CreateContactDiseases, {
   dispatch,
   getState
}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactDiseasesCreate`, {
      Request: {
         CreateContactDiseases
      }
   })

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   const contactId = getState().contact.selectedContact.id === undefined ? CreateContactDiseases.contactId : getState().contact.selectedContact.id
   await dispatch(getDataContactDiseases({contactId}))
   return CreateContactDiseases
})

/** Note of contact */

export const getDataContactNote = createAsyncThunk('contact/getDataContactNote', async params => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactNoteList`, {params})
   return {
      dataNote: response.data.Response.items
   }
})

export const addContactNote = createAsyncThunk('contact/addContactNote', async (CreateContactNote, {
   dispatch,
   getState
}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactNoteCreate`, {
      Request: {
         CreateContactNote
      }
   })

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   const contactId = getState().contact.selectedContact.id === undefined ? CreateContactNote.contactId : getState().contact.selectedContact.id
   await dispatch(getDataContactNote({contactId}))
   return CreateContactNote
})

/** Dcoument of contact */

export const getDataContactDocument = createAsyncThunk('contact/getDataContactDocument', async params => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactDocumentList`, {params})
   return {
      dataDocument: response.data.Response.items
   }
})

export const addContactDocument = createAsyncThunk('contact/addContactDocument', async (CreateContactDocument, {
   dispatch,
   getState
}) => {
   const uniqueId = Math.random().toString(16).slice(2)

   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactDocumentCreate`, CreateContactDocument, {
      headers: {
         'Content-Type': `multipart/form-data; boundary=${uniqueId}`
      }
   })

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   const contactId = getState().contact.selectedContact.id === undefined ? CreateContactDocument.contactId : getState().contact.selectedContact.id
   await dispatch(getDataContactDocument({contactId}))
   return CreateContactDocument
})

export const deleteContactDocument = createAsyncThunk('call/deleteContactDocument', async (id, { dispatch, getState }) => {
   const response = await axios.delete(`${process.env.REACT_APP_BASE_API}Schedule/contact/contactDocumentDelete/${id}`)

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   const contactId = getState().contact.selectedContact.id
   await dispatch(getDataContactDocument({contactId}))
   return id
})

export const appContactsSlice = createSlice({
   name: 'contact',
   initialState: {
      data: [],
      total: 1,
      params: {},
      allData: [],
      selectedContact: [],
      dataMedicine: [],
      dataDiseases: [],
      dataNote: [],
      dataDocument: []
   },
   reducers: {},
   extraReducers: builder => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
         })
         .addCase(getContact.fulfilled, (state, action) => {
            state.selectedContact = action.payload
         })
         .addCase(getDataContactMedicine.fulfilled, (state, action) => {
            state.dataMedicine = action.payload.dataMedicine
         })
         .addCase(getDataContactDiseases.fulfilled, (state, action) => {
            state.dataDiseases = action.payload.dataDiseases
         })
         .addCase(getDataContactNote.fulfilled, (state, action) => {
            state.dataNote = action.payload.dataNote
         })
         .addCase(getDataContactDocument.fulfilled, (state, action) => {
            state.dataDocument = action.payload.dataDocument
         })
   }
})

export default appContactsSlice.reducer
