import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import axios from "axios"
import toast from "react-hot-toast"

export const getData = createAsyncThunk('protocol/getData', async params => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/protocol/protocolList`, {params})
    return {
        params,
        data: response.data.Response.items,
        totalPages: response.data.Response.total_items
    }
})
export const getProtocol = createAsyncThunk('protocol/getProtocol', async protocolId => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/protocol/protocolEdit/${protocolId}`)
    return response.data.Response.item
})

export const addProtocol = createAsyncThunk('protocol/addProtocol', async (CreateProtocol, { dispatch, getState }) => {

    const uniqueId = Math.random().toString(16).slice(2)
    const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/protocol/protocolCreate`, CreateProtocol, {
        headers: {
            'Content-Type': `multipart/form-data; boundary=${uniqueId}`
        }
    })

    if (response.data.Response.Code === 1) {
        toast.success('Event success')
    } else {
        toast.error('Error')
    }

    await dispatch(getData(getState().protocol.params))
    return CreateProtocol
})

export const updateProtocol = createAsyncThunk('protocol/updateProtocol', async (UpdateProtocol, { dispatch, getState }) => {
    const response = await axios.put(`${process.env.REACT_APP_BASE_API}Schedule/protocol/protocolUpdate`, {
        Request: {
            UpdateProtocol
        }
    })

    if (response.data.Response.Code === 1) {
        toast.success('Event success')
    } else {
        toast.error('Error')
    }

    await dispatch(getData(getState().protocol.params))
    await dispatch(getProtocol(getState().protocol.selectedProtocol.id))
    return UpdateProtocol
})

export const changeStatus = createAsyncThunk('protocol/changeStatus', async (ChangeStatusProtocol, { dispatch, getState }) => {
    const response = await axios.patch(`${process.env.REACT_APP_BASE_API}Schedule/protocol/protocolChangeStatus`, {
        Request: {
            ChangeStatusProtocol
        }
    })

    if (response.data.Response.Code === 1) {
        toast.success('Event success')
    } else {
        toast.error('Error')
    }

    await dispatch(getData(getState().protocol.params))
    return ChangeStatusProtocol
})

/** Request for protocol flowchart */
export const getDataProtocolFlowchart = createAsyncThunk('protocol/getDataProtocolFlowchart', async params => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/protocol/protocolFlowchartList`, {params})
    return {
        dataFlowchart: response.data.Response.items
    }
})

export const addProtocolFlowchart = createAsyncThunk('protocol/addProtocolFlowchart', async (CreateProtocolFlowchart, { dispatch, getState }) => {

    const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/protocolFlowchart/protocolFlowchartCreate`, {
        Request: {
            CreateProtocolFlowchart
        }
    })

    if (response.data.Response.Code === 1) {
        toast.success('Event success')
    } else {
        toast.error('Error')
    }

    const protocolId = getState().protocol.selectedProtocol.id
    await dispatch(getDataProtocolFlowchart({protocolId}))
    return CreateProtocolFlowchart
})

/** Request for protocol activity */
export const getDataProtocolActivity = createAsyncThunk('protocol/getDataProtocolActivity', async params => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/protocol/protocolActivityList`, {params})
    return {
        dataActivity: response.data.Response.items
    }
})

export const addProtocolActivity = createAsyncThunk('protocol/addProtocolActivity', async (CreateProtocolActivity, { dispatch, getState }) => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/protocolActivity/protocolActivityCreate`, {
        Request: {
            CreateProtocolActivity
        }
    })

    if (response.data.Response.Code === 1) {
        toast.success('Event success')
    } else {
        toast.error('Error')
    }

    const protocolId = getState().protocol.selectedProtocol.id
    await dispatch(getDataProtocolActivity({protocolId}))
    await dispatch(getDataProtocolFlowchart({protocolId}))

    return CreateProtocolActivity
})

export const updateProtocolActivity = createAsyncThunk('protocol/updateProtocolFlowchart', async (UpdateProtocolActivity, { dispatch, getState }) => {

    const response = await axios.patch(`${process.env.REACT_APP_BASE_API}Schedule/protocolFlowchart/protocolActivityUpdate`, {
        Request: {
            UpdateProtocolActivity
        }
    })

    if (response.data.Response.Code === 1) {
        toast.success('Event success')
    } else {
        toast.error('Error')
    }

    const protocolId = getState().protocol.selectedProtocol.id
    await dispatch(getDataProtocolActivity({protocolId}))
    await dispatch(getDataProtocolFlowchart({protocolId}))
    return UpdateProtocolFlowchart
})

/** Request for protocol visit */
export const getDataProtocolVisit = createAsyncThunk('protocol/getDataProtocolVisit', async params => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/protocol/protocolVisitList`, {params})
    return {
        dataVisit: response.data.Response.items
    }
})

export const addProtocolVisit = createAsyncThunk('protocol/addProtocolVisit', async (CreateProtocolVisit, { dispatch, getState }) => {
    const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/protocolVisit/protocolVisitCreate`, {
        Request: {
            CreateProtocolVisit
        }
    })

    if (response.data.Response.Code === 1) {
        toast.success('Event success')
    } else {
        toast.error('Error')
    }

    const protocolId = getState().protocol.selectedProtocol.id
    await dispatch(getDataProtocolVisit({protocolId}))
    await dispatch(getDataProtocolFlowchart({protocolId}))

    return CreateProtocolVisit
})

/** calc price flowchart */
export const calcProtocolFlowchart = createAsyncThunk('protocol/calcProtocolFlowchart', async params => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/protocol/calcProtocolFlowchart`, {params})
    return {
        dataCalc: response.data.Response
    }
})

export const deleteProtocolActivity = createAsyncThunk('protocol/deleteProtocolActivity', async (id, { dispatch, getState }) => {
    const response = await axios.delete(`${process.env.REACT_APP_BASE_API}Schedule/protocolActivity/protocolActivityDelete/${id}`)

    if (response.data.Response.Code === 1) {
        toast.success('Event success')
    } else {
        toast.error('Error')
    }

    const protocolId = getState().protocol.selectedProtocol.id
    await dispatch(getDataProtocolActivity({protocolId}))
    await dispatch(getDataProtocolFlowchart({protocolId}))

    return id
})

export const appProtocolSlice = createSlice({
    name: 'protocol',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        selectedProtocol: null,
        dataActivity: [],
        dataVisit: [],
        dataFlowchart: [],
        dataCalc: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getProtocol.fulfilled, (state, action) => {
                state.selectedProtocol = action.payload
            })
            .addCase(getDataProtocolActivity.fulfilled, (state, action) => {
                state.dataActivity = action.payload.dataActivity
            })
            .addCase(getDataProtocolVisit.fulfilled, (state, action) => {
                state.dataVisit = action.payload.dataVisit
            })
           .addCase(getDataProtocolFlowchart.fulfilled, (state, action) => {
               state.dataFlowchart = action.payload.dataFlowchart
           })
           .addCase(calcProtocolFlowchart.fulfilled, (state, action) => {
               state.dataCalc = action.payload.dataCalc
           })
    }
})
export default appProtocolSlice.reducer
