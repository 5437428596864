// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('activity/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/activity/activityList`, {params})
  return {
    params,
    data: response.data.Response.items,
    totalPages: response.data.Response.total_items
  }
})

export const getActivity = createAsyncThunk('activity/getActivity', async id => {
  let response = {}
  if (id > 0) {
    response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/activity/activityEdit/${id}`)
    return response.data.Response.item
  } else return response
})

export const addActivity = createAsyncThunk('activity/addActivity', async (CreateActivity, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/activity/activityCreate`, {
    Request: {
      CreateActivity
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().activity.params))
  await dispatch(getAllData())
  return CreateActivity
})

export const updateActivity = createAsyncThunk('activity/addUpdate', async (UpdateActivity, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_API}Schedule/activity/activityUpdate`, {
    Request: {
      UpdateActivity
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().activity.params))
  return UpdateActivity
})

export const deleteActivity = createAsyncThunk('activity/deleteActivity', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_BASE_API}Schedule/activity/activityDelete/${id}`)

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().activity.params))
  return id
})

export const appActivitysSlice = createSlice({
  name: 'activity',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedActivity: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getActivity.fulfilled, (state, action) => {
        state.selectedActivity = action.payload
      })
  }
})

export default appActivitysSlice.reducer
