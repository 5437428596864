// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getDataReportExport = createAsyncThunk('reportFinance/getDataReportExport', async (data) => {
   const params = data[0]
   const func = data[1]
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/reportFinance/reportFinanceExport`, {params})

   func(false)

   const url = `${process.env.REACT_APP_BASE_FILE}showFile.php?watch=${response.data.Response.name}&t=repository`
   window.location.assign(url)

   return response
})


/** Get List protocol for report **/
export const getLabel = createAsyncThunk('appCalendar/getLabel', async calendars => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/label/listLabelEHR`, {calendars})
   return response.data.Response.items
})

export const appReportFinanceSlice = createSlice({
   name: 'reportFinance',
   initialState: {
      data: [],
      params: {},
      allData: [],
      report: [],
      labelList: []
   },
   reducers: {},
   extraReducers: builder => {
      builder
         .addCase(getDataReportExport.fulfilled, (state, action) => {
            state.report = action.payload
         })
         .addCase(getLabel.fulfilled, (state, action) => {
            state.labelList = action.payload
         })
   }
})

export default appReportFinanceSlice.reducer