// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('users/getData', async params => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/reportSchedule/reportScheduleList`, {params})
   return {
      params,
      data: response.data.Response.items,
      totalPages: response.data.Response.total_items
   }
})

/** Get List protocol for calendar **/
export const getStatus = createAsyncThunk('appCalendar/getStatus', async calendars => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/status/statusList`, {calendars})
   return response.data.Response.items
})

/** Get List user for guest calendar **/
export const getGuest = createAsyncThunk('appCalendar/getGuest', async calendars => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/calendarGuest/calendarGuestList`, {calendars})
   return response.data.Response.items
})

/** Get List protocol for calendar **/
export const getLabel = createAsyncThunk('appCalendar/getLabel', async calendars => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/label/labelList`, {calendars})
   return response.data.Response.items
})

export const getReport = createAsyncThunk('appCalendar/getReport', params => {
   const response = axios.get(`${process.env.REACT_APP_BASE_API}Schedule/reportSchedule/reportScheduleExport`, {params})
   return response.data.Response.items
})

export const appReportScheduleSlice = createSlice({
   name: 'reportSchedule',
   initialState: {
      data: [],
      total: 0,
      params: {},
      allData: [],
      statusList: [],
      userGuest: [],
      labelList: [],
      report: []
   },
   reducers: {},
   extraReducers: builder => {
      builder
         .addCase(getData.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
            state.total = action.payload.totalPages
         })
         .addCase(getStatus.fulfilled, (state, action) => {
            state.statusList = action.payload
         })
         .addCase(getGuest.fulfilled, (state, action) => {
            state.userGuest = action.payload
         })
         .addCase(getLabel.fulfilled, (state, action) => {
            state.labelList = action.payload
            state.selectedCalendars = action.payload
         })
         .addCase(getReport.fulfilled, (state, action) => {
            state.report = action.payload
         })
   }
})

export default appReportScheduleSlice.reducer