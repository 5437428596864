// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('typeOfExams/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/typeOfExam/typeOfExamList`, params)
  return {
    params,
    data: response.data.Response.items,
    totalPages: response.data.Response.total_items
  }
})

export const getTypeOfExam = createAsyncThunk('typeOfExams/getTypeOfExam', async id => {
  let response = {}
  if (id > 0) {
    response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/typeOfExam/typeOfExamEdit/${id}`)
    return response.data.Response.item
  } else return response
})

export const addTypeOfExam = createAsyncThunk('typeOfExams/addTypeOfExam', async (CreateTypeOfExam, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/typeOfExam/typeOfExamCreate`, {
    Request: {
      CreateTypeOfExam
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().typeOfExams.params))
  await dispatch(getAllData())
  return CreateTypeOfExam
})

export const updateTypeOfExam = createAsyncThunk('typeOfExams/addUpdate', async (UpdateTypeOfExam, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_API}Schedule/typeOfExam/typeOfExamUpdate`, {
    Request: {
      UpdateTypeOfExam
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().typeOfExams.params))
  return UpdateTypeOfExam
})

export const deleteTypeOfExam = createAsyncThunk('typeOfExams/deleteTypeOfExam', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_BASE_API}Schedule/typeOfExam/typeOfExamDelete/${id}`)

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().typeOfExams.params))
  return id
})

export const appTypeOfExamsSlice = createSlice({
  name: 'typeOfExams',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTypeOfExam: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTypeOfExam.fulfilled, (state, action) => {
        state.selectedTypeOfExam = action.payload
      })
  }
})

export default appTypeOfExamsSlice.reducer
