// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('menu/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/menu/menuList`, {params})
  return {
    params,
    data: response.data.Response.items,
    totalPages: response.data.Response.total_items
  }
})

export const getMenu = createAsyncThunk('menu/getMenu', async id => {
  let response = {}
  if (id > 0) {
    response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/menu/menuEdit/${id}`)
    return response.data.Response.item
  } else {
    return response
  }
})

export const addMenu = createAsyncThunk('menu/addMenu', async (CreateMenu, { dispatch, getState }) => {
  const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/menu/menuCreate`, {
    Request: {
      CreateMenu
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().menus.params))
  await dispatch(getAllData())
  return CreateMenu
})

export const updateMenu = createAsyncThunk('menu/addMenu', async (UpdateMenu, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_API}Schedule/menu/menuUpdate`, {
    Request: {
      UpdateMenu
    }
  })

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().menus.params))
  await dispatch(getAllData())
  return UpdateMenu
})

export const deleteMenu = createAsyncThunk('menu/deleteMenu', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_BASE_API}Schedule/menu/menuDelete/${id}`)

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().menus.params))
  await dispatch(getAllData())
  return id
})

export const appMenusSlice = createSlice({
  name: 'menus',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedMenu: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getMenu.fulfilled, (state, action) => {
        state.selectedMenu = action.payload
      })
  }
})

export default appMenusSlice.reducer
