// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('call/getData', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/call/callList`, {params})
  return {
    params,
    data: response.data.Response.items,
    totalPages: response.data.Response.total_items
  }
})

export const getCall = createAsyncThunk('call/getCall', async params => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/call/callEdit`, {params})

  if (response.data.Response.Code !== 1) {
    toast(
       response.data.Response.Description,
       {
         duration: 6000
       }
    )
  }
  return {
    paramsEdit: params,
    selectedCall: response.data.Response.item
  }

})

export const updateCall = createAsyncThunk('call/addUpdate', async (UpdateCall, { dispatch, getState }) => {
  const response = await axios.put(`${process.env.REACT_APP_BASE_API}Schedule/call/callUpdate`, {
    Request: {
      UpdateCall
    }
  })

  if (parseInt(response.data.Response.Code) === 1) {
    toast.success('Event success')
  } else {
    toast.error(response.data.Response.Description)
  }

  await dispatch(getCall(getState().call.paramsEdit))
  return UpdateCall
})

export const deleteCall = createAsyncThunk('call/deleteCall', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`${process.env.REACT_APP_BASE_API}Schedule/call/callDelete/${id}`)

  if (response.data.Response.Code === 1) {
    toast.success('Event success')
  } else {
    toast.error('Error')
  }

  await dispatch(getData(getState().call.params))
  return id
})

export const appCallsSlice = createSlice({
  name: 'call',
  initialState: {
    data: [],
    total: 1,
    params: {},
    paramsEdit: {},
    allData: [],
    selectedCall: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
       .addCase(getCall.fulfilled, (state, action) => {
         state.selectedCall = action.payload.selectedCall
         state.paramsEdit = action.payload.paramsEdit
       })
  }
})

export default appCallsSlice.reducer
