// ** Redux Imports
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

// ** local storage

export const fetchEvents = createAsyncThunk('appCalendar/fetchEvents', async calendars => {

   const userData = JSON.parse(localStorage.getItem('userData'))

   let calendarLabel
   if (calendars.all) {
      calendarLabel = "all"
   } else {
      calendarLabel = calendars.labels
   }

   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/calendar/calendarList`, {
      Request: {
         ListCalendar: {
            getAll: calendarLabel,
            guests: calendars.guests || [],
            userId: userData.id,
            shareCalendar: userData.shareCalendar,
            filterDate: calendars.filterDate,
            typefilter: calendars.typefilter
         }
      }
   })

   return response.data.Response.items
})

export const addEvent = createAsyncThunk('appCalendar/addEvent', async (CreateCalendar, {dispatch, getState}) => {
   const user = JSON.parse(localStorage.getItem('userData'))
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/calendar/calendarCreate`, {
         Request: {
            CreateCalendar,
            UserId: user.id
         }
      }
   )

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   const filterLabel = getState().calendar.selectedCalendars
   const filterGuests = getState().calendar.selectedGuest
   const tmpDate = new Date()

   await dispatch(
      fetchEvents(
         {
            labels: filterLabel, 
            guests: filterGuests, 
            all: false,
            filterDate: tmpDate.toJSON(),
            typefilter: "dayGridMonth"
         }
      )
   )

   return CreateCalendar
})

export const updateEvent = createAsyncThunk('appCalendar/updateEvent', async (event, {dispatch, getState}) => {
   const response = await axios.put(`${process.env.REACT_APP_BASE_API}Schedule/calendar/calendarUpdate`, {
      Request: {
         UpdateCalendar: event
      }
   })

   const filterLabel = getState().calendar.selectedCalendars
   const filterGuests = getState().calendar.selectedGuest
   const tmpDate = new Date()

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(
      fetchEvents(
         {
            labels: filterLabel, 
            guests: filterGuests, 
            all: false,
            filterDate: tmpDate.toJSON(),
            typefilter: "dayGridMonth"
         }
      )
   )

   return event
})

export const updateFilterGuests = createAsyncThunk('appCalendar/updateFilterGuests', async (filter, {
   dispatch,
   getState
}) => {
   if (filter) {
      const filterLabel = getState().calendar.selectedCalendars
      await dispatch(fetchEvents({guests: filter, labels: filterLabel, all: false}))
   }
   return filter
})

export const updateFilter = createAsyncThunk('appCalendar/updateFilter', async (filter, {dispatch, getState}) => {
   if (!filter.checked) {
      await dispatch(fetchEvents({labels: getState().calendar.selectedCalendars.filter(i => i.value !== filter.value)}))
   } else {
      const addLabel = getState().calendar.labelList.find((label) => label.value === filter.value)
      await dispatch(fetchEvents({labels: [...getState().calendar.selectedCalendars, addLabel], all: false}))
   }
   return filter
})

export const updateAllFilters = createAsyncThunk('appCalendar/updateAllFilters', async (value, {
   dispatch,
   getState
}) => {
   let selected = []
   if (value === true) {
      selected = [...getState().calendar.labelList]
   }

   await dispatch(fetchEvents({labels: selected, all: false}))
   return value
})

export const changeStatusEvent = createAsyncThunk('appCalendar/changeStatusEvent', async (values) => {
   await axios.patch(`${process.env.REACT_APP_BASE_API}Schedule/calendar/calendarChangeStatus`, {
      Request: {
         ChangeStatusCalendar: values
      }
   })
   return id
})

/** Get List user for guest calendar **/
export const getGuest = createAsyncThunk('appCalendar/getGuest', async calendars => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/calendarGuest/calendarGuestList`, {calendars})
   return response.data.Response.items
})

/** Get List protocol for calendar **/
export const getLabel = createAsyncThunk('appCalendar/getLabel', async calendars => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/label/labelList`, {calendars})
   return response.data.Response.items
})

/** Get List protocol for calendar **/
export const getStatus = createAsyncThunk('appCalendar/getStatus', async calendars => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/status/statusList`, {calendars})
   return response.data.Response.items
})

/** List Block Schedule */
export const listBlockSchedule = createAsyncThunk('appCalendar/listBlockSchedule', async filters => {
   const response = await axios.get(`${process.env.REACT_APP_BASE_API}Schedule/calendarBlock/calendarBlockList`, filters)
   return response.data.Response.items
})

/** Block Schedule */
export const addBlockSchedule = createAsyncThunk('appCalendar/addBlockSchedule', async (blockCalendar, {
   dispatch
}) => {
   const response = await axios.post(`${process.env.REACT_APP_BASE_API}Schedule/calendarBlock/calendarBlockCreate`, {
         Request: {
            CreateCalendarBlock: blockCalendar
         }
      }
   )

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(listBlockSchedule())
   return blockCalendar
})

/** remove block schedule **/
export const removeBlockSchedule = createAsyncThunk('appCalendar/removeBlockSchedule', async (id, {dispatch}) => {
   const response = await axios.delete(`${process.env.REACT_APP_BASE_API}Schedule/calendarBlock/calendarBlockDelete/${id}`)

   if (response.data.Response.Code === 1) {
      toast.success('Event success')
   } else {
      toast.error('Error')
   }

   await dispatch(listBlockSchedule())
   return id
})

export const appCalendarSlice = createSlice({
   name: 'appCalendar',
   initialState: {
      events: [],
      selectedEvent: {},
      selectedCalendars: [],
      selectedGuest: [],
      userGuest: [],
      labelList: [],
      statusList: [],
      eventsBlock: [],
      loading: null
   },
   reducers: {
      selectEvent: (state, action) => {
         state.selectedEvent = action.payload
      }
   },
   extraReducers: {
      [fetchEvents.pending](state) {
         state.loading = 'PENDING'
      },
      [fetchEvents.fulfilled](state, { payload }) {
         state.loading = 'FULFILLED'
         state.events = payload
      },
      [fetchEvents.rejected](state) {
         state.loading = 'REJECTED'
      },
      [updateFilter.fulfilled](state, { payload }) {
         if (!payload.checked) {
            state.selectedCalendars.splice(state.selectedCalendars.findIndex(item => item.value === payload.value), 1)
         } else {
            const addLabel = state.labelList.find((label) => label.value === payload.value)
            state.selectedCalendars.push(addLabel)
         }
      },
      [updateAllFilters.fulfilled](state, { payload }) {
         const value = payload
            let selected = []
            if (value === true) {
               selected = [...state.labelList]
            }
            state.selectedCalendars = selected
      },
      [getGuest.fulfilled](state, { payload }) {
         state.userGuest = payload
      },
      [getLabel.fulfilled](state, { payload }) {
         state.labelList = payload
         state.selectedCalendars = payload
      },
      [getStatus.fulfilled](state, { payload }) {
         state.statusList = payload
      },
      [updateFilterGuests.fulfilled](state, { payload }) {
         if (payload) {
            state.selectedGuest = payload
         } else {
            state.selectedGuest = []
         }
      },
      [listBlockSchedule.fulfilled](state, { payload }) {
         state.eventsBlock = payload
      }
   }
})

export const {selectEvent} = appCalendarSlice.actions

export default appCalendarSlice.reducer
